
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import axios from 'axios';

import ImportFilesSelector from './ImportFilesSelector.vue';
import { BModal } from 'bootstrap-vue';

type OperationType = 'insert' | 'update' | 'upsert' | 'delete' | 'save';

interface SelectOption {
  label: string;
  value: string;
}

export interface CurrentScreenUrl {
  query: string;
  queryString: string;
  noPagingQuery: string;
  noPagingQueryString: string;
  entityPath: string;
}

@Component({
  components: {
    ImportFilesSelector,
  },
})
export default class ImporterModal extends Vue {
  @Prop({ required: true }) currentScreenUrl!: CurrentScreenUrl;
  @Prop() urlFactory!: (
    format: string,
    currentScreenUrl: CurrentScreenUrl,
  ) => string;
  @Prop() allowedOperations!: OperationType[];
  @Ref('modal') readonly modal!: BModal;

  importStep = 0;
  importFiles: any = null;
  inProgress = false;
  uploadResult: any = null;
  importing = false;
  importResult = null;
  importStepsActions = ['Upload', 'Import', 'Close'];
  importFileKey = null;
  columnMapping = {};
  encoding: string = 'utf8';
  operationType: SelectOption | null = null;
  dataTag: string = '';
  error: any = null;

  get operationTypeModel() {
    if (this.operationType) {
      return this.operationType;
    }
    if (this.operationTypes.length === 1) {
      return this.operationTypes[0];
    }
    return null;
  }

  set operationTypeModel(value) {
    this.operationType = value;
  }

  get operationTypes(): SelectOption[] {
    const ops =
      this.allowedOperations ??
      (this.fileFormat === 'yaml'
        ? ['save']
        : ['insert', 'update', 'upsert', 'delete']);
    return ops.map((value) => ({
      label: value.toUpperCase(),
      value,
    }));
  }

  get importActionAllowed() {
    switch (this.importStep) {
      case 0:
        return this.importFiles?.length > 0 && !this.inProgress;
      case 1:
        return this.columnMappingValid;
      default:
        return true;
    }
  }

  get fileFormat() {
    if (this.importFiles?.[0]?.type === undefined) {
      return undefined;
    }
    let format;
    switch (this.importFiles?.[0]?.type) {
      case 'text/csv':
        format = 'csv';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        format = 'xlsx';
        break;
      case 'application/x-yaml':
        format = 'yaml';
        break;
      default:
        if (this.importFiles[0]?.name) {
          const name = this.importFiles[0]?.name;
          format = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
          if (format !== 'csv' && format != 'xlsx' && format != 'yaml') {
            format = null;
          }
        }
        break;
    }
    if (!format) {
      console.error('Unsupported file type:', this.importFiles[0]);
      throw new Error('Unknown file type:' + this.importFiles[0]?.type);
    }
    return format;
  }

  get importUrl() {
    let url;
    if (this.urlFactory) {
      url = this.urlFactory(this.fileFormat, this.currentScreenUrl);
    }
    if (!url) {
      url = `/data-api/import/${this.fileFormat}/${this.currentScreenUrl.entityPath}`;
    }
    return url;
  }

  get columnMappingValid() {
    const values = Object.values(this.columnMapping);
    if (values.length === 0 || !this.uploadResult?.tableColumns) {
      return false;
    }
    for (const dbColumn of values) {
      if (dbColumn === false || dbColumn === true) {
        continue;
      }
      if (
        dbColumn &&
        typeof dbColumn === 'object' &&
        dbColumn['userSupplied'] === true &&
        'selected' in dbColumn
      ) {
        continue;
      }
      if (!this.uploadResult.tableColumns.includes(dbColumn)) {
        return false;
      }
    }
    return !!this.operationTypeModel;
  }

  created() {
    this.resetImportModal();
  }

  open() {
    this.resetImportModal();
    this.modal.show();
  }
  resetImportModal() {
    this.importStep = 0;
    this.importFiles = null;
    this.importFileKey = null;
    this.inProgress = false;
    this.uploadResult = null;
    this.importResult = null;
    this.columnMapping = {};
    this.encoding = 'utf8';
    this.error = null;
  }
  modalClosed() {
    this.resetImportModal();
    this.$emit('close');
  }
  importModalAction() {
    switch (this.importStep) {
      case 0:
        return this.uploadFile();
      case 1:
        return this.importFile();
      default:
        this.modal.hide();
    }
  }
  async uploadFile() {
    const formData = new FormData();
    formData.append('file', this.importFiles[0]);

    this.inProgress = true;

    try {
      const result = await axios.post(this.importUrl, formData);
      this.uploadResult = result.data;
      this.importFileKey = this.uploadResult?.key;
      this.columnMapping = this.uploadResult?.suggetedFileToDbColumnMap;
      this.encoding = this.uploadResult?.encoding ?? 'utf8';
      this.error = null;
      this.$nextTick(() => {
        this.importStep = 1;
      });
    } catch (error: any) {
      this.error = {
        message: error.toString(),
        details: error,
      };
      this.$emit('error', {
        data: error,
        title: 'Import file failed',
      });
    } finally {
      this.error = null;
      this.inProgress = false;
    }
  }
  async importFile() {
    this.inProgress = true;
    const params = {
      key: this.importFileKey,
      importType: this.operationTypeModel?.value,
      fileToDbColumnMap: this.columnMapping,
      dataTagName: this.dataTag ? this.dataTag : undefined,
      encoding: this.encoding,
    };
    try {
      const result = await axios.put(this.importUrl, params);
      this.importResult = result.data;
      this.$nextTick(() => {
        this.importStep = 2;
      });
    } catch (error: any) {
      this.error = {
        message: error.toString(),
        details: error,
      };
      this.$emit('error', {
        data: error,
        title: 'Import file failed',
      });
    } finally {
      this.error = null;
      this.inProgress = false;
    }
  }
}
