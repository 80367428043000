<template>
  <div>
    <b-row>
      <b-col
        cols="6"
        lg="3"
        v-for="(file, index) in files"
        :key="index"
        class="mb-2"
      >
        <div class="gid-file-selector__box">
          <div
            class="border rounded d-flex align-items-center justify-content-center p-2 gid-file-selector__image"
          >
            <i class="display-4 text-primary material-icons">storage</i>
            <i
              class="material-icons text-danger rounded-circle gid-file-selector__close"
              @click="removeFile(index)"
              v-if="!readonly"
            >
              remove_circle
            </i>
          </div>
        </div>
        <b-form-group v-if="file" class="mt-2 mb-0">
          {{ file.name }}
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        lg="3"
        class="mb-2 gid-file-selector__add-box"
        v-if="canAdd"
      >
        <div
          class="border rounded d-flex align-items-center justify-content-center h-100 p-2"
        >
          <input
            class="gid-file-selector__add"
            type="file"
            @change="fileSelected"
            multiple
          />
          <i class="text-primary material-icons">library_add</i>
        </div>
      </b-col>
    </b-row>
    <b-alert show v-if="fileTypeError">
      Some of the files you have selected are not supported. Please only use CSV
      or XLSX.
    </b-alert>
  </div>
</template>

<script>
import BaseInput from '@gid/vue-common/components/inputs/mixins/BaseInput';
import { mapGetters } from 'vuex';

export default {
  mixins: [BaseInput],
  props: {
    maxFiles: Number,
    comments: Boolean,
    context: String,
    inputName: {
      type: String,
    },
    variant: String,
    supportedTypes: {
      type: Array,
      default: function () {
        return [
          'text/csv',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/x-yaml',
        ];
      },
    },
    supportedExtensions: {
      type: Array,
      default: function () {
        return ['.csv', '.xlsx', '.yaml', '.yml'];
      },
    },
  },
  data() {
    return {
      files: [],
      fileTypeError: false,
    };
  },
  watch: {
    value(newValue) {
      if (newValue && newValue.length == 0 && this.files.length != 0) {
        this.files = [];
      }
    },
    files: {
      deep: true,
      handler() {
        this.commitOrEmit(this.files);
      },
    },
  },
  computed: {
    ...mapGetters(['access_token']),
    canAdd() {
      if (this.readonly) return false;
      return !this.maxFiles || this.files.length < this.maxFiles;
    },
  },
  created() {
    this.files = this.value ? this.value.files : [];
  },
  methods: {
    fileSelected(event) {
      const selectedFiles = event.target.files;
      this.fileTypeError = false;
      for (let i = 0; i < selectedFiles.length; i++) {
        if (this.maxFiles > 0 && this.files.length + i >= this.maxFiles) {
          break;
        }
        const file = selectedFiles[i];
        let supported = false;
        if (file.type) {
          supported = this.supportedTypes.includes(file.type);
        } else if (file.name) {
          supported = this.supportedExtensions.some((ext) =>
            file.name.toLowerCase().endsWith(ext),
          );
        }

        if (!supported) {
          this.fileTypeError = true;
          console.warn('Unsupported file type:', file.type);
          continue;
        }
        this.addFileAsIs(file);
      }
    },
    addFileAsIs(file) {
      this.files.push(file);
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import '@gid/vue-common/scss/_file-selector.scss';
</style>
